<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Report Form</span>
      </v-card-title>
      <v-card-text>
        <v-progress-linear v-if="formLoading" indeterminate />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <ApolloQuery
                :query="require('@/graphql/client/clientsDropdown.gql')"
              >
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete
                    v-if="data"
                    :items="data.allClients"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="client_id"
                    label="Client"
                    required
                  />
                  <v-autocomplete v-else label="Loading Clients" />
                </template>
              </ApolloQuery>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="types"
                :disabled="formLoading"
                :loading="formLoading"
                item-text="name"
                item-value="value"
                v-model="formData.type"
                label="Type"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.name"
                label="Name"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="permissions"
                :disabled="formLoading"
                :loading="formLoading"
                item-text="name"
                item-value="value"
                v-model="formData.perm"
                label="Permission"
                required
              />
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                v-if="formData.type == 'Single'"
                v-model="formData.source"
                label="Url"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-if="formData.type == 'Dual'"
                v-model="formData.metadata.pending_tab_name"
                label="Pending tab name"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-if="formData.type == 'Dual'"
                v-model="formData.metadata.pending_requests_url"
                label="Pending requests url"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-if="formData.type == 'Dual'"
                v-model="formData.metadata.completed_tab_name"
                label="Completed tab name"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-if="formData.type == 'Dual'"
                v-model="formData.metadata.completed_requests_url"
                label="Completed requests url"
                :disabled="formLoading"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('report-form-closed')"
          :disabled="formLoading"
          >{{ $t("close") }}</v-btn
        >
        <v-btn
          v-if="formData.id"
          color="primary darken-1"
          text
          :loading="formLoading"
          :disabled="formLoading"
          @click="updateReport()"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-else
          color="primary darken-1"
          text
          :disabled="formLoading"
          :loading="formLoading"
          @click="createReport()"
          >{{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: "ReportForm",
  props: {
    report: {
      type: Object,
      default: () => ({
        id: "",
        type: "",
        name: "",
        perm: "",
        source: "",
        metadata: {},
        client: {}
      })
    }
  },
  data: () => ({
    isValid: true,
    mutation: "",
    formLoading: false,
    types: [
      { name: "Single", value: "Single" },
      { name: "Dual", value: "Dual" }
    ],
    permissions: [
      { name: "client_admin", value: "client_admin" },
      { name: "client_user", value: "client_user" },
      { name: "admin", value: "admin" },
      { name: "everybody", value: "" }
    ],
    defaultForm: {
      id: "",
      type: "",
      name: "",
      perm: "client_admin",
      source: "",
      metadata: {},
      client: {}
    },
    formData: {}
  }),
  created() {
    if (this.report.metadata != null) {
      this.report.metadata = JSON.parse(this.report.metadata);
    }
    this.formData = { ...this.defaultForm, ...this.report };
  },
  computed: {
    client_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.report.client !== "undefined") {
          if (typeof this.report.client.id !== "undefined") {
            return this.report.client.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.client = { id: newValue };
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
        this.saveForm();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateReport() {
      this.mutation = require("@/graphql/report/updateReport.gql");
      this.saveForm();
    },
    createReport() {
      this.mutation = require("@/graphql/report/createReport.gql");
      this.saveForm();
    },
    saveForm() {
      this.formLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters
          variables: {
            id: this.formData.id,
            type: this.formData.type,
            name: this.formData.name,
            perm: this.formData.perm,
            source: this.formData.source,
            metadata: JSON.stringify(this.formData.metadata),
            client: this.formData.client.id
          }
        })
        .then(data => {
          // report success
          this.$emit("report-form-closed", data);
          this.$emit("report-form-saved", data);
        })
        .catch(error => {
          // Error
          console.error(error);
          // update user....
          this.formLoading = false;
        });
    }
  },
  watch: {
    report: {
      handler: function() {
        this.formData = { ...this.defaultForm, ...this.report };
      },
      deep: true
    }
  }
};
</script>
