<template>
    <ApolloQuery :query="require('@/graphql/report/report.gql')" :variables="{ id: report_id }">
        <template v-slot="{ result: { loading, error, data } }">
            <v-progress-linear indeterminate v-if="loading" />
            <div v-else-if="data && checkPermissions(data)">
                <v-tabs background-color="primary" dark left>
                    <v-tab left v-if="data.report && data.report.type == 'Single'">Report</v-tab>
                    <v-tab v-if="data.report && data.report.metadata && data.report.metadata != 'null' && data.report.type == 'Dual'">{{metadata(data).pending_tab_name}}</v-tab>
                    <v-tab v-if="data.report && data.report.metadata && data.report.metadata != 'null' && data.report.type == 'Dual'">{{metadata(data).completed_tab_name}}</v-tab>
                    <v-tab-item v-if="data.report && data.report.type == 'Single'">
                        <v-container fluid>
                            <iframe                             
                              :src="data.report.source"
                              frameborder="0"
                              scrolling="yes"
                              seamless="seamless"
                              style="display: block; width: 100%; height: 100vh"
                            >
                            </iframe>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item v-if="data.report && data.report.metadata && data.report.metadata != 'null' && data.report.type == 'Dual'">
                      <v-container fluid>
                        <iframe :src="metadata(data).pending_requests_url" frameborder="0" scrolling="yes" seamless="seamless"
                          style="display: block; width: 100%; height: 100vh">
                        </iframe>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item v-if="data.report && data.report.metadata && data.report.metadata != 'null' && data.report.type == 'Dual'">
                      <v-container fluid>
                        <iframe :src="metadata(data).completed_requests_url" frameborder="0" scrolling="yes" seamless="seamless"
                          style="display: block; width: 100%; height: 100vh">
                        </iframe>
                      </v-container>
                    </v-tab-item>
                </v-tabs>
            </div>
        </template>
    </ApolloQuery>
</template>
<script>
import { authGate } from "@/mixins/authGate";
import { mapGetters } from "vuex";
export default {
  mixins: [authGate],
  name: "ReportViewer",
  props: {
    report_id: {
        type: String
    }
  },
  computed: {
    ...mapGetters({
        authUser: "authUser"
    })
  },
  methods: {
    metadata: function(data) {
        let metadata = data.report.metadata && data.report.metadata != "null"
         ? JSON.parse(data.report.metadata)
         : null;

         if (metadata != null) {
            return metadata
         } else {
            return {
                pending_tab_name: '',
                completed_tab_name: '',
                pending_requests_url: '',
                completed_requests_url: ''
            }
         }
    },
    checkPermissions: function(data) {
        if (this.$store.getters.userForPortalAccess.user_type != "admin") {
            if(this.$store.getters.userForPortalAccess.user_type != "client_admin" || this.$store.getters.userForPortalAccess.user_type == "client_admin" && this.$store.getters.userForPortalAccess.client.id != data.report.client.id) {
                window.location.href = "/unauthorized";
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
  },
  mounted() {
    if (this.report.type == 'Dual' && this.metadata != null) {
      this.$refs.pendingTab.$el.click();
    }
  }
};
</script> 