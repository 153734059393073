<template>
  <v-container fluid>
    <ApolloQuery
      :query="require('@/graphql/report/allReports.gql')"
      :variables="{ client_id: client_id, garbage: rando }"
    >
      <template v-slot="{ result: { loading, error, data } }">
        <v-progress-linear indeterminate v-if="loading" />
        <v-data-iterator
          v-if="data && !loading"
          :items="data.allReports"
          :search="keywords"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ itemsPerPageOptions }"
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <report-card
                  :report.sync="item"
                  @delete-clicked="promptForDelete"
                  @edit-clicked="editReport"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <v-alert type="error" v-else-if="error">
          There was an error loading data. The server said...<br />
          <strong>{{ error }}</strong>
        </v-alert>
      </template>
    </ApolloQuery>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="320"
      v-if="selectedReport.name != ''"
    >
      <v-card>
        <v-card-title class="headline">Delete Report?</v-card-title>
        <v-card-text>
          <p>
            You are about to delete the report
            <strong>{{ selectedReport.name }}</strong
            >. This will remove the report record as well as any associations
            with users and reports. This cannot be undone. Are you sure you want
            to delete this report?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteDialog = false">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="deleteReport(selectedReport)"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ReportCard from "@/views/reports/ReportCard";
export default {
  name: "ReportsGrid",
  components: {
    "report-card": ReportCard
  },
  props: {
    keywords: {
      type: String,
      required: true
    },
    reload: {
      type: Number,
      default: 0
    },
    client_id: {
      type: String
    }
  },
  data: () => ({
    itemsPerPageOptions: [4, 8, 12],
    itemsPerPage: 8,
    deleteDialog: false,
    selectedReport: {},
    rando: 0
  }),
  watch: {
    reload: function(val) {
      this.rando = val;
    }
  },
  methods: {
    promptForDelete(report) {
      this.selectedReport = report;
      this.deleteDialog = true;
    },
    deleteReport(report) {
      this.$apollo
        .mutate({
          // Query
          mutation: require("@/graphql/report/deleteReport.gql"),
          // Parameters
          variables: {
            id: report.id
          }
        })
        .then(data => {
          // report success
          this.$emit("pool-form-closed", data);
          this.rando++;
        })
        .catch(error => {
          // Error
          console.error(error);
          // update user....
        });
      this.deleteDialog = false;
    },
    editReport(report) {
      this.$emit("edit-report", report);
    }
  }
};
</script>
