<template>
  <v-card>
    <v-list-item>
      <v-list-item-avatar color="grey">
        <v-icon dark>{{ this.getIcon(report.type) }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ report.name }}
        <v-list-item-subtitle>
          {{ report.client.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <p>
        Type: {{ report.type }} <br />
        Permission: {{ report.perm }} <br />
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="viewReport">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>View</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editReport">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="promptForDelete">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ReportCard",
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  methods: {
    loadForm(report) {
      this.$emit("loadReportForm", report);
    },
    promptForDelete() {
      this.$emit("delete-clicked", this.report);
    },
    editReport() {
      this.$emit("edit-clicked", this.report);
    },
    viewReport() {
      this.$router.push({
        name: "report-viewer",
        params: {
          client_id: this.report.client.id,
          report_id: this.report.id
        }
      });
    },
    getIcon(type) {
      if (type == "Single") {
        return "mdi-chart-bar";
      } else {
        return "mdi-ticket";
      }
    }
  }
};
</script>
