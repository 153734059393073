<template>
  <v-card>
    <v-card-title>
      <h3>Reports</h3>
      <v-spacer />
      <v-text-field
        v-model="keywords"
        append-icon="mdi-magnify"
        label="Search Reports..."
        clearable
        single-line
        hide-details
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" @click="newReportForm()" fab dark>
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New Report</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <reports-grid
        :keywords.sync="keywords"
        :client_id.sync="client_id"
        :reload.sync="reloadVar"
        @edit-report="editReport"
      />
    </v-card-text>
    <v-dialog v-model="reportFormDialog" persistent max-width="600">
      <report-form
        :report.sync="formReport"
        @report-form-saved="
          closeReportForm();
          reloadGrid();
        "
        @report-form-closed="closeReportForm()"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import ReportsGrid from "@/views/reports/ReportsGrid";
import ReportForm from "@/views/reports/ReportForm";
export default {
  name: "reports",
  components: {
    "reports-grid": ReportsGrid,
    "report-form": ReportForm
  },
  props: ["client_id"],
  data: () => ({
    keywords: "",
    reportFormDialog: false,
    formReport: {},
    reloadVar: 0
  }),
  methods: {
    reloadGrid: function() {
      this.reloadVar++;
    },
    newReportForm: function() {
      this.formReport = {};
      this.reportFormDialog = true;
    },
    closeReportForm: function() {
      this.reportFormDialog = false;
    },
    editReport: function(report) {
      this.formReport = report;
      this.reportFormDialog = true;
    }
  }
};
</script>
